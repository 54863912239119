import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ProjectOverviewPage from 'src/components/project-overview-page';

export default function Page() {
    const { allStrapiProject } = useStaticQuery(
        graphql`
            query projectsOverview {
                allStrapiProject {
                    projects: nodes {
                        title
                        tags {
                            name
                        }
                        description
                        image {
                            url
                        }
                        identifier
                    }
                }
            }
        `
    );

    const { projects } = allStrapiProject;

    return <ProjectOverviewPage projects={projects} />;
}

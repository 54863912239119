import React from 'react';
import { ProjectOverviewObject } from 'src/utils/types';
import { sortBy } from 'lodash';
import ProjectOverviewCard from 'src/components/project-overview-card';
import TitleBar from 'src/components/title-bar';
import MainContainer from 'src/components/main-container';

export default function ProjectOverviewPage(props: {
    projects: ProjectOverviewObject[];
}) {
    return (
        <MainContainer>
            <TitleBar title='Some of the Things I am working on' backTo='/' />

            <div className='flex flex-col space-y-3'>
                {sortBy(props.projects, ['order', 'title']).map(
                    (p: ProjectOverviewObject) => (
                        <ProjectOverviewCard key={p.title} project={p} />
                    )
                )}
            </div>
        </MainContainer>
    );
}

import React from 'react';
import { ProjectOverviewObject } from '../utils/types';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

export default function ProjectOverviewCard(props: {
    project: ProjectOverviewObject;
}) {
    const { project } = props;

    return (
        <Link to={`/project/${project.identifier}`}>
            <div
                className={
                    'relative flex flex-col sm:flex-row w-full sm:h-66' +
                    ' bg-white shadow rounded overflow-hidden'
                }
            >
                <div className='relative flex flex-col flex-shrink-0 w-full h-auto bg-gray-200 sm:w-96 sm:h-66'>
                    <div className='self-stretch flex-grow centering-row'>
                        <h2 className='pt-1 my-2 text-lg text-gray-900 sm:my-auto font-weight-600'>
                            {project.title}
                        </h2>
                    </div>
                    <div className='box-border h-auto mx-2 mb-2 overflow-hidden rounded sm:m-1 sm:w-94 sm:h-52'>
                        <img
                            src={project.image.url}
                            className={'relative w-full h-full'}
                        />
                    </div>
                </div>
                <div className='flex flex-col px-3 py-3 md:px-4'>
                    <article>
                        <section
                            className={
                                'relative w-full markdown text-xl text-gray-800 break-normal'
                            }
                        >
                            <ReactMarkdown skipHtml={false}>
                                {project.description}
                            </ReactMarkdown>
                        </section>
                    </article>
                    <div className='self-stretch flex-grow' />
                    <div className='relative float-left w-full mt-2 space-x-1 space-y-1 no-selection'>
                        {project.tags.map(tag => (
                            <div
                                key={tag.name}
                                className={
                                    'relative inline-block px-2 leading-6 rounded ' +
                                    'text-sm font-weight-600 text-opacity-90 ' +
                                    'bg-gray-200 text-gray-800 '
                                }
                            >
                                {tag.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Link>
    );
}
